.img_container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;
}

.image {
	display: block;
	width: 420px;
	height: 280px;
}

.link {
	text-decoration: none;
	color: #fff;
	font-size: 11px;
	text-align: start;
}

@media (max-width: 450px) {
	.img_container {
		padding: 1em;
	}

	.image {
		width: calc(100vw - 2em);
		height: calc((100vw - 2em) * (2/3));
	}
}