.title {
	font-family: 'B612', sans-serif;
	font-size: 21px;
	text-transform: uppercase;
	text-align: center;
    position: relative;
	top: 50%;
    transform: translate(0, -50%);
}

.wrapper {
	overflow: visible;
}

.loader {
	width: 120px;
	height: 120px;
	margin: 34px 0;
	position: relative;
	overflow: visible;
}

.loader span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotate(calc(18deg * var(--i)));
}

.loader span::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 50%;
	transform: scale(0);
	animation: animate 2s linear infinite;
	animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
	0% {
		transform: scale(0);
	}
	10% {
		transform: scale(1.2);
	}
	80%, 100% {
		transform: scale(0);
	}
}

.plane {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: visible;
	animation: rotating 2s linear infinite;
}

.plane::before {
	content: '';
	display: block;
	background-image: url('../assets/plane.png');
	background-size: 40px;
	width: 40px;
	height: 40px;
	position: absolute;
	top: -25px;
	left: 0px;
	transform: rotate(0deg);
}

@keyframes rotating {
	0% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(370deg);
	}
}