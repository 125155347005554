.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
}

.modalcontent {
    background: #252525;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 13px;
}

.button {
    padding: 13px 0;
	margin: 1rem;
    width: 89px;
	height: 55px;
	color: #fff;
	background-color: #00205b;
	border-color: #fff;
	border-style: solid;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 500;
	transition: background-color 0.13s;
	-webkit-tap-highlight-color: #005587;
}

.button:active:hover {
	background-color: #005587;
}

@media (hover: hover) {
	.button:hover:enabled {
		cursor: pointer;
		background-color: #005587;
	}
}
