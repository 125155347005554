* {
	font-family: "Roboto", sans-serif;
	color: #fff;
	box-sizing: border-box;
}

html, body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

body {
    background: #121213;
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: .25;
}

hr:not([size]) {
	height: 1px;
}

h1 {
	font-size: 21px;
	text-transform: uppercase;
	color: #fff;
	margin-top: 0;
	font-weight: 100;
}

a {
	color: #6399ae;
}

button:not(:disabled) {
	cursor: pointer;
}

.text_center {
	text-align: center;
}

.close {
	position: fixed;
	top: 13px;
	right: 13px;
	padding: 0;
}

.icon {
	line-height: 0;
	background: none;
	border: none;
}

.icon:hover {
	box-shadow: none;
}

.share_overlay {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(18,18,19, 1);
}

.overlay_content {
	display: flex;
	flex-direction: column;
	width: 90%;
	max-width: 500px;
	color: #fff;
	background-color: #121213;
	margin: 0 auto;
	row-gap: 34px;
}

.overlay_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.miniplane {
	width: 24px;
	height: 24px;
	mask: url('./assets/plane.png') center/contain;
	-webkit-mask: url('./assets/plane.png') center/contain;
}

.miniplane span {
	visibility: hidden;
}
