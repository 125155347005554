.time_wrapper {
	display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
	justify-content: flex-end;
	margin: 2em auto;
}

.results_wrapper {
	display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
	justify-content: center;
	margin: 2em auto;
}

.completion_time {
    font-size: 34px;
	color: #fff;
}

.miniplane_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 5px;
}

.footer {
    display: flex;
	align-items: center;
	width: 100%;
	max-width: 500px;
	margin: 2em auto;
}

.countdown {
    text-align: center;
	margin: auto;
	font-size: 34px;
	width: 50%;
	border-right: 1px solid #fff;
}

.share {
    display: flex;
	width: 50%;
}

@media (max-width: 500px) {
	.footer {
		flex-direction: column;
		row-gap: 2em;
	}
	
	.countdown {
		border-right: 0;
	}
}