.share_button {
    display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-size: 21px;
	font-weight: bold;
	text-transform: uppercase;
    width: 144px;
	height: 55px;
	margin: auto auto;
	color: #fff;
	background-color: #00205b;
	border-color: #fff;
	border-style: solid;
	border-radius: 5px;
	transition: background-color 0.13s;
	-webkit-tap-highlight-color: #005587;
}

.share_button:active:hover {
	background-color: #005587;
}

@media (hover: hover) {
	.share_button:hover:enabled {
		cursor: pointer;
		background-color: #005587;
	}
}

.results-image {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-113%);
	width: 300px;
}
