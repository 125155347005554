.timer_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.plane_wrapper {
	display: flex;
	flex-direction: column;
	max-width: 100%;
}

.miniplane_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 5px;
}

.answers {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 13px;
	width: 100%;
	max-width: 420px;
	margin: 0 auto;
}
