.timer {
	text-align: center;
	margin: 1rem auto;
	font-size: 34px;
}

@keyframes animate {
	0% {
		color: red;
	}
	100% {
		color: #fff;
	}
}

@media (max-height: 450px) {
	.img_container {
		padding: 1em;
	}

	.image {
		width: calc(100vw - 2em);
		height: calc((100vw - 2em) * (2/3));
	}
}